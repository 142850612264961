<template>
	<div class="floor-management-wrapper">
        <div class="common-form">
            <div class="common-form-layout">
                <global-page-back
                    detailPageTitle="楼栋管理"
                    @handlerGlobalType="handlerGlobalType"
                >
                </global-page-back>
            </div>
        </div>
		<!-- 楼栋管理 楼栋管理 -->
		<div v-if="!errorShow">
			<loading :loadingShow="loadingShow"></loading>
			<div v-if="!loadingShow" class="content-main">
                <div class="filter-wrap">
                    <expand-filter :formData="formData"  marginBottom="0px" @clickBtn="clickBtn" @changeSel="changeSel" :showExpandBtn="false" :closeWidth="340"  @changeBtnFormType="changeBtnFormType" >
                    </expand-filter>
                    <div class="button-line"></div>
                    <div style="display: flex;flex: 1;">
                        <operation-btn
                            type="ClassroomManagement" :linkType="false" :exportLoading="exportLoading" @selectAll="selectAll"
                            @inverseSelection="inverseSelection" @clear="clear" @add="add" @batchDelete="batchDelete"
                            @buildingManagement="floorManagement" @importFile="importFile" @exportFile="exportFile"
					    ></operation-btn>
                    </div>
                </div>

				<table-data ref="table" id="table" v-loading="loadingTable" :config="tableConfig"
					:table-data="tableData" :background-header="backgroundHeader" :header-color="headerColor"
					@handleSelectionChange="handleSelectionChange">
					<template v-slot:operation="operate">
						<el-button v-has-permi="['schoolbuildingroom:edit']" type="primary" @click="handleEdit(operate.data)" class="table-success-button">
							编辑
						</el-button>
						<el-button v-has-permi="['schoolbuildingroom:delete']" type="danger" @click="handleDel(operate.data)" class="table-danger-button">
							删除
						</el-button>
					</template>
				</table-data>
				<Pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow" @pagination="getList" />
				<!-- 新增弹窗 -->
				<dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose" class="add-dialog">
					<floor-forms ref="floorForms" :ruleForm="ruleForm" :rules="rules"
						:classroomType="classroomType"
						:floorList="floorList"
						@selectBuilding="selectBuilding"
						@submitForm="submitForm"
                        class="common-form"
                        >
						</floor-forms>
				</dialog-wrapper>
				<!-- 导入弹窗 -->
				<dialog-wrapper :dialogObj="dialogObjImport" @handleClose="handleCloseImport">
					<import-floor-info ref="importExcelFile" :importExcel="importExcel" :active="active"
						:successNum="successNum" @download="download" @uploadChange="uploadChange" @delFile="delFile"
						@importSaveFile="importSaveFile" @resetFile="resetFile" @finish="finish">
					</import-floor-info>
				</dialog-wrapper>
			</div>
		</div>
		<error v-else :errorShow="errorShow"></error>
	</div>
</template>
<script>
	import {
		Error,
		Loading,
		Pagination,
		DialogWrapper,
	} from "common-local"
    import GlobalPageBack from '../../Sub/GlobalPageBack/index.vue';
	import ExpandFilter from '../../Sub/ExpandFilter.vue';
	import OperationBtn from '../Sub/OperationBtn.vue';
	import TableData from "@/components/scrollWrapper/Sub/TableData"
	import FloorForms from './FloorForms.vue'
	import ImportFloorInfo from './ImportFloorInfo.vue';
	import { downloadFile } from "@/libs/utils.js";
	import { SManagerBaseClassroomModule } from '@/models/SManagerBaseClassroom.js';
    import onResize from "@/mixins/onResize"

	import { mapState } from 'vuex'
	export default {
        mixins: [onResize],
		name: "FloorManagement",
		components: {
			Error,
			Loading,
			TableData,
			Pagination,
			DialogWrapper,
			ExpandFilter,
			OperationBtn,
			FloorForms,
			ImportFloorInfo,
            GlobalPageBack
		},
		data() {
			return {
				// 功能组件
				errorShow: false,
				loadingShow: false,
				loadingTable: false,
				// 分页器
				total: 0,
				formData: {
					formInline: {
						schoolId: '',
						buildingType: '',
						buildingName: ''
					},
					data: [{
						type: 'select',
						placeholder: '楼栋类型',
						key: 'buildingType',
						value: '',
						list: []
					},{
						type: 'input',
						placeholder: '楼栋名称',
						key: 'buildingName',
						value: ''
					}],
					btnList: [
						{
							type: 'primary',
							text: '查询',
							fn: 'primary',
							auth: 'product:list',
						},
						{
							type: "enquiry",
							text: '重置',
							fn: 'reset'
						}
					],
					btnFormType: true
				},
				listQuery: {
					pageNum: 1,
					pageRow: 20,
					schoolId: '',
					floorId: '',
					buildingType: '',
					buildingId: '',
					buildingName: ''
				},
				// 表头
				backgroundHeader: '#fafbfc',
				// 表头字体
				headerColor: '#595959',
				tableConfig: {
					check: true,
					height: "",
					thead: [{
						label: '楼栋名称',
						prop: 'buildingName',
						// labelWidth: '180px'
					},{
						label: '楼栋类型',
						prop: 'buildingTypeName'
					},{
						label: '楼栋描述',
						prop: 'buildingDes'
					},{
						label: '楼栋图片',
						type: 'images',
						prop: 'buildingUrl',
                        align: "left",
					},{
						label: '楼层',
						prop: 'floorName',
                        align:'center'
					},{
						label: '房间数',
						prop: 'buildingRoomNum',
						// labelWidth: '80px',
                        align:'center'
					},{
                        label: '排序',
                        prop: 'sort',
                        // labelWidth: '80px',
                        sortable: true,
                        align:'center'
                    },{
						label: '创建时间',
						prop: 'createTime',
						labelWidth: '160px',
                        align:'left',
                        type:'function',
                        callBack(row) {
                            return row.createTime ? row.createTime.slice(0, 16):'-'
                        },
					},{
						label: '操作',
						type: 'slot',
						slotName: 'operation',
                        labelWidth: '180',
					}]
				},
				tableData: [],
				// 弹出框配置
				dialogObj: {
					title: '添加楼栋',
					dialogVisible: false,
					width: '640px'
				},
				dialogObjImport: {
					title: '导入楼栋信息',
					dialogVisible: false,
					width: '640px'
				},
				ruleForm: {
					id: '',
					schoolId: '',
					buildingType: '', // 楼栋类型
					buildingName: '', // 楼栋名称
					buildingDes: '',  // 楼栋描述
					buildingUrl: '', // 楼栋图片
					// 楼栋层数
					floorList: [],
					buildingRoomNum: '', // 房间号
					sort: '', // 排序
				},
				rules: {
					buildingType: [{
						required: true,
						message: '请选择楼栋类型',
						trigger: ['change', 'blur']
					}],
					buildingName: [{
						required: true,
						message: '请输入楼栋名称',
						trigger: 'blur'
					}],
					floorName: [{
						required: true,
						message: '请输入楼层层数',
						trigger: ['change', 'blur']
					}]
				},
				classroomType: [], // 楼栋类型
				floorList: [],
				floorIdsList: [],
				deleteCollection: [], // 删除操作
				importExcel: {
					file: '',
					errType: '',
					schoolId: ''
				}, // 导入楼栋
				active: '0', // 进度
				successNum: 0, // 成功条数
				exportLoading: false
			}
		},
		computed: {
			...mapState({
				schoolId: state => state.schoolId
			})
		},
		created() {
			this.init();
		},
		methods: {
			/**
			 * @Description:
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			init() {
				this.initAssignment();
				this.initPageData();
			},
			initAssignment() {
				this.formData.formInline.schoolId = this.schoolId;
				this.ruleForm.schoolId = this.schoolId;
				this.importExcel.schoolId = this.schoolId;
			},
			async initPageData() {
				await this.getClassroomType()
				await this.getList()
			},
			/**
			 * @Description: 楼栋类型
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 武东超
			 * @Date: 2023-01-13 16:58:33
			 */
			async getClassroomType() {
				const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
				await sManagerBaseClassroomModule.getConditionList({
					schoolId: this.schoolId,
					dictKey: 'buildingType'
				}).then((res) => {
					if (res.data.code == '200') {
						res.data.data.forEach((item) => {
							this.$set(item, 'label', item.name)
							this.$set(item, 'value', item.id)
						})
						this.formData.data.forEach((item) => {
							console.log(item, 'item')
							if (item.key == 'buildingType') {
								item.list = res.data.data;
							}
						})
						this.classroomType = res.data.data;
						// console.log(res.data.data, '楼栋类型')
					} else if (res.data.msg) {
						this.$message.error(res.data.msg)
					}
				})
			},
			/**
			 * @Description: 获取选择内容
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 羡国柱
			 * @Date: 2023-01-13 16:58:33
			 */
			changeSel (data) {
				console.log(data)
			},
			// 返回
			floorManagement() {
				this.$emit('floorManagement')
			},
			/**
			 * @Description: 提交
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 * @param {*} data
			 */
			clickBtn(data) {
				switch (data.item.fn) {
					case "primary": //查询
						this.formData.data.forEach((item) => {
							Object.keys(this.listQuery).forEach((key) => {
								if (item.key == key) {
									this.listQuery[key] = item.value;
								}
							})
						})
						this.getList()
						break;
					case "reset": //重置
						this.formData.data.forEach((item) => {
							item.value = '';
						})
						Object.keys(this.listQuery).forEach((key) => {
							this.listQuery[key] = ''
						})
						this.listQuery.pageNum = 1;
						this.listQuery.pageRow = 20;
						this.getList()
						break;
					default:
						break;
				}
			},
            /**
             * @Description: 返回列表页面
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2023-07-21 11:09:23
             */
            handlerGlobalType () {
                this.$emit('floorManagement')
            },
			/**
			 * @Description: 关闭弹窗
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			handleClose() {
				console.log("关闭弹窗")
				this.$refs.floorForms.resetForm();
				this.dialogObj.dialogVisible = false;
			},
			/**
			 * @Description:
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			getList() {
				const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
				this.listQuery.schoolId = this.schoolId;
				sManagerBaseClassroomModule.getFloorList(this.listQuery).then((res) => {
					if (res.data.code == '200') {
						this.tableData = res.data.data.list;
						this.total = res.data.data.totalCount;
					} else if (res.data.msg) {
						this.$message.error(res.data.msg)
					}
				})
			},
			/**
			 * @Description: 操作项
			 * selectAll 全选
			 * inverseSelection 反旋
			 * clear 清除
			 * add 添加
			 * batchDelete 批量删除
			 * floorManagement 返回
			 * importFile 导入
			 * exportFile 导出
			 * getSelectTableData 获取表格选中数据
			 */
			selectAll() {
				this.$refs.table.$refs.multipleTable.toggleAllSelection()
				this.getSelectTableData()
			},
			inverseSelection() {
				this.tableData.forEach((item) => {
					this.$refs.table.$refs.multipleTable.toggleRowSelection(item);
				});
				this.getSelectTableData()
			},
			clear() {
				this.$refs.table.$refs.multipleTable.clearSelection()
				this.getSelectTableData()
			},
			add() {
				this.ruleForm.id = '';
				this.ruleForm.sort = 1;
				this.dialogObj.title = '添加楼栋';
				this.ruleForm.floorList = [];
				this.ruleForm.floorList.push({
					id: '123456789',
				    floorName: '1',
					floorRoomNums: '0'
				});
				// this.$refs.floorForms.resetFields();
				// this.$refs.floorForms.resetForm();
				this.dialogObj.dialogVisible = true;
			},
			// 批量删除
			batchDelete() {
				if (this.deleteCollection.length == 0) {
					return this.$message.error('未选择任何数据～')
				}
				this.$confirm('确定要删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
					sManagerBaseClassroomModule.schoolFloorRoomDel(this.deleteCollection).then((res) => {
						if (res.data.code == '200') {
							this.$message.success('删除成功')
							this.getList()
						} else if (res.data.msg) {
							this.$message.error(res.data.msg)
						}
					})
				}).catch(() => {});
			},

			importFile() {
				this.dialogObjImport.dialogVisible = true;
			},
			exportFile() {
				let obj = {
					schoolId: this.schoolId,
					buildingName: '',
                    buildingType: ''
				};
				this.formData.data.forEach((item) => {
					Object.keys(obj).forEach((key) => {
						if (item.key == key) {
							obj[key] = item.value;
						}
					})
				})
				this.exportLoading = true;
				downloadFile({
						url: "/school/schoolBuilding/export",
						form: obj
					},
					() => {
						this.$message.success("导出成功");
						this.exportLoading = false;
					},
					() => {}
				);
			},
			getSelectTableData() {
				this.deleteCollection = [];
				const selectData = this.$refs.table.$refs.multipleTable.selection;

				selectData.forEach((item) => {
					let obj = {
						id: item.id
					}
					this.deleteCollection.push(obj);
				})
			},
			handleSelectionChange(e) {
				this.deleteCollection = [];

				e.forEach((item) => {
					let obj = {
						id: item.id
					}
					this.deleteCollection.push(obj);
				})
			},
			/**
			 * @Description: 关闭导入弹窗
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 羡国柱
			 * @Date: 2023-01-13 16:58:33
			 */
			handleCloseImport() {
				this.$refs.importExcelFile.delFile();
				this.finish()
			},
			/**
			 * @Description:
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			async handleEdit(data) {
				console.log(data, 'data')
				let datasList = [];
				let dataFloorList = [];
				let dataList = [];
				let floorsDList = [];
				let floorRoomNumsList = [];
				this.ruleForm.floorList = [];
				this.floorIdsList = [];

                Object.keys(this.ruleForm).forEach((key) => {
					Object.keys(data).forEach((subKey) => {
						if (key == subKey) {
							this.ruleForm[key] = data[subKey];
						}
					})
				})
				// 楼层name
				if(data.floorName && data.floorName!= "") {
					dataList = data.floorName.split(",");
				}
				// 楼层id
				if(data.floorIds && data.floorIds!= "") {
					floorsDList = data.floorIds.split(",");
				}
				// 当前楼层教室数量
				if(data.floorRoomNums && data.floorRoomNums!= "") {
					floorRoomNumsList = data.floorRoomNums.split(",");
				}


				datasList = dataList.map((floorName, i) => ({ floorName, id: floorsDList[i] }))

				for(let j = 0; j < datasList.length; j++) {
					dataFloorList.push({
						id: datasList[j].id,
						floorName: datasList[j].floorName,
						floorRoomNums: floorRoomNumsList[j]
					})
				}
				console.log(dataFloorList)
				this.ruleForm.id = data.id;
				this.ruleForm.floorList = dataFloorList;
				// console.log(this.ruleForm.floorList);


				// Object.keys(this.ruleForm).forEach((key) => {
				// 	Object.keys(data).forEach((subKey) => {
				// 		if (key == subKey) {
				// 			this.ruleForm[key] = data[subKey];
				// 		}
				// 	})
				// })

				if (!data.sort) {
				    this.ruleForm.sort = undefined;
				}

				console.log(this.ruleForm, 'this.ruleForm')
				await this.selectBuilding('edit')

				this.dialogObj.title = '编辑楼栋信息'
				this.dialogObj.dialogVisible = true;
			},
			/**
			 * @Description:
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			async selectBuilding (type) {
			    console.log(type)
			},
			/**
			 * @Description:
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			// 列表删除
			handleDel(data) {
				this.$confirm('确定要删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
					sManagerBaseClassroomModule.schoolFloorRoomDel([{
						id: data.id
					}]).then((res) => {
						if (res.data.code == '200') {
							this.$message.success('删除成功')
							this.getList()
						} else if (res.data.msg) {
							this.$message.error(res.data.msg)
						}
					})
				}).catch(() => {});
			},
			/**
			 * @Description:
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-18 17:33:28
			 */
			 handlerFloorSortChange () {
			    // console.log(this.ruleForm.sort, 'this.ruleForm.sort')
			    //
			    if (!this.ruleForm.sort) {
			        this.ruleForm.sort = undefined;
			    }
			 },
			/**
			 * @Description: 新增/编辑
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			submitForm() {
				if (!this.ruleForm.sort) {
				    this.ruleForm.sort = '';
				}
				const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
				sManagerBaseClassroomModule.floorSave(this.ruleForm).then((res) => {
					if (res.data.code == '200') {
						this.$message.success(res.data.msg)
                        this.handleClose()
						this.$refs.floorForms.resetForm()
						this.dialogObj.dialogVisible = false;
						this.ruleForm.id = '';
						this.getList();
						this.ruleForm.floorList = [];
						this.ruleForm.floorList.push({
							id: '123456789',
						    floorName: '1层',
							floorRoomNums: '0'
						});
						// console.log("成功")
					} else if (res.data.msg) {
						this.$message.error(res.data.msg)
					}
				})
			},
			/**
			 * @Description:
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			// 下载模板
			download() {
				this._get(
					"/school/schoolBuilding/template"
				).then((res) => {
					if (res.data.code === "200") {
						downloadFile({
								url: res.data.data,
								method: "get",
							},
							() => {},
							() => {}
						);
					}
				});
			},
			/**
			 * @Description: 上传文件
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 武东超
			 * @Date: 2023-01-13 14:32:23
			 */
			uploadChange(file) {
				this.importExcel.file = file.raw;
				console.log(file, 'file')
			},
			/**
			 * @Description: 清除上传的文件
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			delFile() {
				this.importExcel.file = ''
			},
			/**
			 * @Description: 导入
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			importSaveFile() {
				if (!this.importExcel.file) {
					return this.$message.error('请上传文件')
				}
				if (!this.importExcel.errType) {
					return this.$message.error('请选择导入设置')
				}
				console.log(this.importExcel, '数据')
				let formData = new FormData();
				formData.append("file", this.importExcel.file);
				formData.append("errType", this.importExcel.errType);
				formData.append("schoolId", this.schoolId);

				const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
				sManagerBaseClassroomModule.importExcelFloor(formData).then((res) => {
					if (res.data.code == '200') {
						console.log(res.data, '导入成功')
						this.$message.success('导入成功')
						this.active = this.active + '1';
						console.log('res.data.data', res.data.data)
						this.successNum = res.data.data;
					} else if (res.data.code == "20013") {
						console.log(res.data, '导入错误')
						this.downloadErrorList(res.data);
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			// 导出导入文件错误信息
			downloadErrorList(data) {
				this.$confirm(data.msg, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						downloadFile({
								url: "/school/schoolBuilding/errorExport",
								method: "post",
								form: {
									paramData: data.info.paramData
								},
							},
							() => {},
							() => {

							}
						);
					})
					.catch(() => {});
			},
			/**
			 * @Description: 重置
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author:
			 * @Date: 2023-01-13 16:58:33
			 */
			resetFile() {
				console.log(this.importExcel, 'this.importExcel')
				this.importExcel.file = '';
				this.importExcel.errType = '';
			},
			/**
			 * @Description: 导入完成
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 武东超
			 * @Date: 2023-01-13 16:58:33
			 */
			finish(type) {
				this.dialogObjImport.dialogVisible = false;
				this.importExcel.file = '';
				this.importExcel.errType = '';
				this.active = '0';
				this.successNum = 0;
				if (type == 'getNew') {
					this.getList();
				}

			},
		}
	}
</script>
<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 4px;

    .expand-filter {
        padding: 0;
    }
}
</style>
<style>
	.floor-management-wrapper .button-text{
		color: #4095e5;
		font-size: 16px;
		font-weight: 600;
		padding: 2px;
	}

	.floor-management-wrapper .button-text::before {
		display: inline-block;
		content: "";
		width: 20px;
		height: 18px;
		background: url(../../../../assets/images/go_back_icon.png) no-repeat;
		background-size: 100%;
	}
</style>
